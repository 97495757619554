<template>
  <div class="email-setting">
    <div class="text-neutral-800 text-xl font-bold mb-2">Email Settings</div>
    <div class="desc-text mb-5 text-base">
      Use the settings below to manage and configure your email settings.
    </div>
    <div class="label font-bold mb-1 text-base leading-5">
      Email sender name
    </div>
    <div class="desc-text mb-2 text-base">
      Your users will see this as the sender in all emails sent by FeedBear.
    </div>
    <form action @submit="saveEmailSettings()">
      <input
        id="project-name"
        placeholder="Project Name"
        v-model="emailSender"
        autocomplete="off"
        type="text"
        name="post[project_name]"
        class="input mb-5 lg:w-1/2 sm:w-full"
        required
      />
      <div class="label font-bold text-base leading-5 mb-1">
        Mailing address
      </div>
      <div class="desc-text font-normal text-base leading-5 mb-2">
        Business address to show at the bottom of all emails. This should be a
        PO Box or physical mailing address.
      </div>
      <input
        id="mailing-address"
        placeholder="5603 Manitou Road, Minnetrista, MN 55331"
        v-model="mailingAddress"
        autocomplete="off"
        type="text"
        name="post[mailing_address]"
        class="input mb-5 lg:w-1/2 sm:w-full"
        required
      />
      <div class="label font-bold text-base leading-5 mb-1">
        Reply-to address
      </div>
      <div class="desc-text font-normal text-base leading-5 mb-2">
        Enter a public email address where you want to receive emails from
        users. e.g. support@domain.com
      </div>
      <input
        id="reply-to-address"
        placeholder="no-reply@feedbear.com"
        v-model="replyToAddress"
        autocomplete="off"
        type="text"
        name="post[mailing_address]"
        class="input mb-5 lg:w-1/2 sm:w-full"
        required
      />

      <div class="" v-if="currentUserOwner">
        <h2 class="label font-bold text-base leading-5 mb-1">
          Email notifications
        </h2>

        <p class="desc-text font-normal text-base leading-5 mb-2">
          Use the options below to determine which actions should trigger email
          notifications to your users.
        </p>
        <p class="desc-text font-normal text-base leading-5 mb-2">
          If an action is enabled, users will be able to enable or disable
          notifications in their account settings if they want to.
        </p>
        <div class="mt-4 mb-8">
          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              id="new_comment_notification"
              v-model="newCommentNotification"
              class="custom-checkbox cursor-pointer"
              true-value="1"
              false-value="0"
            />
            <label for="new_comment_notification" class="">
              New comment on an idea
            </label>
          </div>
          <span class="block pl-6 text-neutral-700"
            >Notifies authors about new comments on ideas they posted</span
          >

          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              id="idea_status_update_notification"
              v-model="ideaStatusUpdateNotification"
              class="custom-checkbox cursor-pointer"
              true-value="1"
              false-value="0"
            />
            <label for="idea_status_update_notification" class="">
              Status update of ideas
            </label>
          </div>
          <span class="block pl-6 text-neutral-700"
            >Notifies authors about status changes (e.g. from Planned to Done)
            on ideas</span
          >

          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              v-model="upvotedIdeaStatusUpdateNotification"
              id="upvoted_idea_status_update_notification"
              class="custom-checkbox cursor-pointer"
              true-value="1"
              false-value="0"
            />
            <label for="upvoted_idea_status_update_notification" class="">
              Status update of upvoted ideas
            </label>
          </div>
          <span class="block pl-6 text-neutral-700"
            >Notifies users who upvoted an idea when the status changes</span
          >

          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              v-model="newMentionNotification"
              id="new_mention_notification"
              class="custom-checkbox cursor-pointer"
              true-value="1"
              false-value="0"
            />
            <label for="new_mention_notification" class="">
              New mention in a comment
            </label>
          </div>
          <span class="block pl-6 text-neutral-700"
            >Notifies users when they are mentioned in a comment</span
          >

          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              id="new_idea_notification"
              v-model="newIdeaNotification"
              class="custom-checkbox cursor-pointer"
              true-value="1"
              false-value="0"
            />
            <label for="new_idea_notification" class=""> New ideas </label>
          </div>
          <span class="block pl-6 text-neutral-700"
            >Notifies admin users when a new idea is posted on a board</span
          >
        </div>
      </div>

      <div class="" v-else>
        <h2 class="label font-bold text-base leading-5 mb-1">
          Email notifications
        </h2>

        <p class="desc-text font-normal text-base leading-5 mb-2">
          Only the owner can manage these options. To manage your own email
          notifications, visit your Account settings.
        </p>
      </div>
      <input type="submit" class="btn-main pl-8 pr-8 mt-12" value="Save" />
    </form>
  </div>
</template>

<script>
export default {
  name: "email-setting",
  props: {
    saveEmailSettingsUrl: String,
    getEmailSettingsUrl: String,
    currentUserOwner: Boolean,
  },
  data() {
    return {
      emailSender: "",
      mailingAddress: "",
      replyToAddress: "",
      newCommentNotification: false,
      newIdeaNotification: false,
      newMentionNotification: false,
      upvotedIdeaStatusUpdateNotification: false,
      ideaStatusUpdateNotification: false,
    };
  },
  mounted() {
    Rails.ajax({
      url: this.getEmailSettingsUrl,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        if (data.email_settings.email_sender_name)
          this.emailSender = data.email_settings.email_sender_name;
        else this.emailSender = "";
        if (data.email_settings.mailing_address)
          this.mailingAddress = data.email_settings.mailing_address;
        else this.mailingAddress = "";
        if (data.email_settings.reply_to_address)
          this.replyToAddress = data.email_settings.reply_to_address;
        else this.replyToAddress = "";
        this.newCommentNotification = data.email_settings
          .new_comment_notification
          ? 1
          : 0;
        this.newIdeaNotification = data.email_settings.new_idea_notification
          ? 1
          : 0;
        this.newMentionNotification = data.email_settings
          .new_mention_notification
          ? 1
          : 0;
        this.upvotedIdeaStatusUpdateNotification = data.email_settings
          .upvoted_idea_status_update_notification
          ? 1
          : 0;
        this.ideaStatusUpdateNotification = data.email_settings
          .idea_status_update_notification
          ? 1
          : 0;
      },
      error: (data, status) => {
        console.log(data);
      },
    });
  },
  methods: {
    saveEmailSettings() {
      let data = new FormData();
      data.append("email_sender_name", this.emailSender);
      data.append("mailing_address", this.mailingAddress);
      data.append("reply_to_address", this.replyToAddress);
      data.append(
        "project[new_comment_notification]",
        this.newCommentNotification
      );
      data.append("project[new_idea_notification]", this.newIdeaNotification);
      data.append(
        "project[new_mention_notification]",
        this.newMentionNotification
      );
      data.append(
        "project[upvoted_idea_status_update_notification]",
        this.upvotedIdeaStatusUpdateNotification
      );
      data.append(
        "project[idea_status_update_notification]",
        this.ideaStatusUpdateNotification
      );
      Rails.ajax({
        url: this.saveEmailSettingsUrl,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          window.location.reload();
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-setting {
  .desc-text {
    color: #666666;
  }
  .label {
    color: #525252;
  }
  .submit-btn {
    background-color: #333333;
  }
  .submit-btn:hover {
    background: white;
  }
}
</style>
